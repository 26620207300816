import { Api } from "../../services/Api";
import CartService from "../../services/CartService";

export default () => ({
    thoughtBarModalOpen: false,
    thoughtBarSaving: false,

    selectedProductId: "",
    selectedOptionId: "",
    currentImageId: "",
    lineItemId: "",

    options: {},

    eligibleVariants: [],
    productOptions: [],
    variantOptions: [],

    reset() {
        this.thoughtBarModalOpen = false;
        this.thoughtBarSaving = false;
        this.selectedProductId = "";
        this.selectedOptionId = "";
        this.options = {};
        this.variantOptions = [];
        this.qty = 1;
    },

    thoughtBarModalInit(
        eligibleVariants,
        lineItemOptions,
        lineItemId,
        lineItemQty = null
    ) {
        this.eligibleVariants = eligibleVariants;
        this.lineItemId = lineItemId;

        this.productOptions = [];
        eligibleVariants
            .map((v) => v.product)
            .filter((item, index, obj) => item.enabled)
            .forEach((product) => {
                // Prints out all thought bar flavors
                const option = {
                    id: product.id,
                    title: product.title,
                };

                this.productOptions.push(option);
            });

        let addedIds = [];
        this.productOptions = this.productOptions.filter((options) => {
            if (addedIds.indexOf(options.id) == -1) {
                addedIds.push(options.id);
                return true;
            }
            return false;
        });

        if (this.productOptions.length === 1) {
            this.selectedProductId = this.productOptions[0].id;
            this.updateVariantOptions();
        }

        this.variantSelected();

        setTimeout(() => {
            // there should only be one line item option
            if (
                lineItemId &&
                lineItemOptions.bundleSelections &&
                Object.keys(lineItemOptions.bundleSelections).length > 0
            ) {
                let selectedOptionIdFromLineItem = Object.keys(
                    lineItemOptions.bundleSelections
                )[0];
                this.thoughtBarModalOpen = true;
                let selectedVariant = eligibleVariants.filter(
                    (v) => v.id == selectedOptionIdFromLineItem
                );
                if (selectedVariant.length > 0) {
                    this.selectedProductId = selectedVariant[0].product.id;
                    this.updateVariantOptions();
                    this.selectedOptionId = selectedOptionIdFromLineItem;
                    this.variantSelected();
                }
            }
        }, 300);

        if (lineItemQty) {
            this.qty = parseInt(lineItemQty + "");
        }
    },

    variantSelected() {
        if (this.selectedOptionId) {
            this.currentImageId = this.selectedOptionId;
        } else {
            if (this.selectedProductId && this.variantOptions.length > 0) {
                this.currentImageId = "" + this.variantOptions[0].id;
            } else if (this.eligibleVariants.length > 0) {
                this.currentImageId = "" + this.eligibleVariants[0].id;
            } else {
                this.currentImageId = "";
            }
        }
        console.log(this.currentImageId);
    },

    updateVariantOptions() {
        this.variantOptions = [];
        this.selectedOptionId = "";

        this.eligibleVariants
            .filter((v) => v.product.id == this.selectedProductId)
            .forEach((variant) => {
                console.log(variant.title);
                const option = {
                    id: variant.id,
                    title: variant.title.replaceAll(
                        variant.product.title + " - ",
                        ""
                    ),
                };

                this.variantOptions.push(option);
            });

        if (this.variantOptions.length === 1) {
            this.selectedOptionId = this.variantOptions[0].id;
        }

        this.variantSelected();
    },

    async addToCart($dialog) {
        let selectedAmount = 0;

        if (!this.selectedProductId || !this.selectedOptionId) {
            alert("Please pick a Celebration Bar.");
            return;
        }

        this.options = {
            [this.selectedOptionId]: 1,
        };

        Object.entries(this.options).forEach(([id, amount]) => {
            selectedAmount += amount;
        });

        if (selectedAmount < this.bundleLimit) {
            alert("Please pick a Celebration Bar and Celebration Bar Flavor.");
            return;
        }

        try {
            let options = {
                bundleSelections: {},
            };
            Object.entries(this.options).forEach(([id, amount]) => {
                if (amount > 0) {
                    options.bundleSelections[id] = amount;
                }
            });
            this.thoughtBarSaving = true;

            let data = {
                purchasableId: this.purchasableId,
                qty: this.qty,
                options,
            };

            if (this.lineItemId) {
                // TODO:
                data = {
                    lineItems: {
                        [this.lineItemId]: {
                            options,
                            qty: this.qty,
                        },
                    },
                };
            }

            const res = await Api.post(
                "/actions/commerce/cart/update-cart",
                data
            );
            if (res.data.errors) {
                // TODO:
                return;
            }

            if (this.lineItemId) {
                window.location.href = "/shop/cart";
                return;
            }

            await CartService.getCart(this);
            this.$dispatch("addToCart", data);
            this.thoughtBarSaving = false;

            $dialog.close();
            this.reset();
        } catch (e) {
            this.thoughtBarSaving = false;
            if (
                e?.response?.data?.errors &&
                e.response.data.errors["lineItems.0.qty"]
            ) {
                alert(e.response.data.errors["lineItems.0.qty"][0]);
                return;
            }
            console.error(e);
        }
    },
});
